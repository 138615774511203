
.menu {
    position: absolute;
    background-color: var(--background-color_gray);
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 180px;
    padding: 10px 10px 10px 20px
  }
  
  .menu-item {
    display: block; /* 确保每个项目是块级元素 */
    padding: 8px 12px;
    cursor: pointer;
    color: var(--response-color);
    border-bottom: 0.5px solid #3a3838; /* 添加底部边框 */
  }
  
  .menu-item:last-child {
    border-bottom: none; /* 移除最后一个项目的底部边框 */
  }
  
  .menu-item:hover {
    background-color:var(--background-gradient-end);
  }