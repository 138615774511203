.header_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* 确保子元素从容器的开始位置排列 */
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: 5%;
    margin-right: 5%;
}

.header {
    display: inline-block;
    /* 确保宽度根据内容自适应 */
    padding: 10px;
    background-color: var(--background-gradient-start);
    color: var(--bitcoin-color);
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
}

.line {
    width: 100%;
    margin-top: 4px;
    height: 1px;
    background-color: var(--background-gradient-start);
}



.miner_container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row; /* 水平排列子元素 */
    align-items:flex-start; /* 垂直居中对齐子元素 */
    justify-content: flex-start; /* 子元素从左到右排列 */
    position: relative;
  }

.miner {
    width: 20%;
    /* 宽度根据内容自适应 */
    height: auto;
    /* 高度自动调整，保持比例 */
    display: block;
    /* 将图片设为块级元素 */
    /* 移除自动居中 */
    border: 2px solid #000;
    /* 图片边框 */
    border-radius: 10px;
    /* 图片圆角 */
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.5);
}

.miner_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
 
}
.miner_title {
    margin-top: 10px;
    color: var(--bitcoin-color);
    font-size: 25px;
    font-weight: bold;
}
.miner_tx {
    margin-top: 10px;
    color: var(--bitcoin-color2);
    font-size: 20px;
    line-height: 1.5; /* 设置行间距 */
    font-family: 'Lobster', 'Courier New', monospace; /* 使用自定义字体 */

}
.highlight {
  color: var(--bitcoin-color);
  font-weight: bold;
}
.normal_tx {
  margin-top: 10px;
  color: var(--bitcoin-color2);
  font-size: 20px;
  line-height: 1.5; /* 设置行间距 */
  font-family: 'Lobster', 'Courier New', monospace; /* 使用自定义字体 */

}


/**blacklist**/
.list-container {
    display: flex;
    flex-wrap: wrap; /* 超出宽度时自动换行 */
    gap: 10px; /* 列表项之间的间距 */
    padding: 10px;
  }
  
  .list-item {
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color:var(--background-color_gray1);
  }
  
  .item-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px; /* 图标和名称之间的间距 */
  }
  
  .item-name {
    font-size: 14px;
    color: var(--text-color4);
  }

