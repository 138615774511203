.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明的黑色背景 */
    z-index: 999; /* 确保覆盖在其他元素之上 */
    display: none; /* 默认隐藏 */
  }
  
.menuWallet {
    position: fixed; /* 使菜单相对于屏幕固定 */
    top: 50%; /* 距离顶部 50% */
    left: 50%; /* 距离左侧 50% */
    transform: translate(-50%, -50%); /* 将元素向上和向左移动50%，实现完全居中 */
    max-width: 300px;
    width: 100%;
    background-color: var(--background-gradient-start);
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* 确保菜单在覆盖层之上 */
    padding: 10px;
    display: flex;
    flex-direction: column; /* 使菜单项垂直排列 */
  }
  
  .menuWallet-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #3c3838;
    cursor: pointer;
  }
  .menuWallet-item:hover {
    background-color: #200e56
  }
  
  .menuWallet-item:last-child {
    border-bottom: none;
  }
  
  .menuWallet-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .menuWallet-text {
    flex-grow: 1;
    text-align: left;
    color:aliceblue;
    font-size: 20px;
  }
 