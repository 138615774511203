.homepage {
  height: 100%;
  width: 100%;
}

button {
  padding: 10px 20px;
  font-size: 1.5rem;
}


.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 0 auto;
  margin-bottom: 40px;
  border-radius: 50%;
  width: 50%;
  /* 使视频居中 */
  height: 50%;
  box-shadow: 0 0 100px rgba(255, 215, 0, 0.9);
}

.video-element {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* 适应容器尺寸 */
}


/* 父容器样式 */
.home_container {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  align-items: flex-start;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  /* 默认水平排列 */
  gap: 20px;
  /* 左右内容之间的间距 */
}

/* 左侧内容样式 */
.left-content {
  flex: 1;
  min-width: 20%;
  /* 限制最大宽度 */
  display: flex;
  justify-content: start;
  align-items: center;

}

.right_title {
  color: var(--bitcoin-color);
  font-size: 25px;
  font-weight: bold;
  font-family: 'Lobster', 'Courier New', monospace;
  /* 使用自定义字体 */

}

/* 右侧内容样式 */
.right-content {
  flex: 2;
  word-wrap: break-word;
  /* 让长单词换行 */
  overflow-wrap: break-word;
  /* 支持换行 */

}

/* 图片样式 */
.left-content img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.5);
}

/* 文本样式 */
.description-text {
  font-size: 16px;
  line-height: 1.5;
}

/* 响应式设置 */
@media (max-width: 768px) {
  .home_container {
    flex-direction: column;
    /* 在较小屏幕上改为垂直排列 */
    align-items: flex-start;
  }

  .right-content {
    width: 100%;
    /* 在小屏幕上，右侧内容占满容器宽度 */
  }

  .video-container {
    width: 70%;
    height: 70%;
  }
}


#myPieChart {
  width: 100% !important;
  height: auto !important;
  max-width: 100%;
  /* 设置一个合适的最大宽度 */
  margin: 0 auto;
  /* 居中对齐 */
  display: block;
  /* 确保居中 */
}