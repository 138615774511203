/* src/bubble/bubble.css */

.bubble-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 每行4个气泡 */
    gap: 10px; /* 调整间距 */
    justify-items: center;
    width: 100%;
    max-width: 800px; /* 设置容器的最大宽度 */
    margin: 0 auto;
  }
  
  /* 气泡样式 */
  .bubble {
    width: 100%;
    max-width: 80px; /* 设置最大宽度 */
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    animation: float 3s infinite ease-in-out alternate;
    box-shadow: 0 0 30px rgba(255, 215, 0, 0.9);
  }
  
  /* 浮动动画 */
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-20px);
    }
  }
  
  .bubble img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }