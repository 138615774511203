.pospage {
  height: 100%;
  width: 100%;
  padding-left: 30px;
}
.feeRate_info {
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  padding: 5px;
  color: #8772c4;
  font-size: 15px;

}

.highlight {
  color: #8772c4;
}

/* body, h1, h2, h3, h4, h5, h6, p, a, span, div, input, button { */
  /* font-family: 'IBM Plex Mono', monospace; 强制应用字体 */
/* } */

/* 底部全屏宽度的横线 */
.full-width-line {
  width: 100%;
  height: 2px;
  background-color: #4d24c6;
  /* 线的颜色 */
}

.horizontal-container {
  display: flex;
  flex-direction: row;
  /* 横向排列 */
  align-items: center;
  /* 垂直居中 */
  justify-content: space-around;
  /* 子元素均匀分布 */
  gap: 20px;
  background-color: #220f5a;
  margin-top: 20px;
  margin-right: 30px;
  padding: 15px;
  border-radius: 8px;
  flex-wrap: wrap;/* 允许内容换行 */
}

/* 点击区块展示mint内容 */
.mint-details {
  color: var(--bitcoin-color);
  display: flex;
  flex-direction: column; /* 让 miner_info 内部的内容垂直排列 */
  align-items:center; /* 将 h2 和 miner_info 水平排列 */
  gap: 10px; /* 设置 h2 和 miner_info 之间的间距 */

}

.start_mining_blinking-text {
  animation: blink 1s infinite; /* 1秒闪烁一次，持续无限次 */
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/**cliam 按钮**/
.container_stake {
  display: flex;
  align-items: center;
  gap: 12px; /* 调整按钮与标题之间的距离 */
}

.stake-text {
  font-size: 24px;
  color: #f0a500; /* 橙色文字 */
  font-weight: bold;
  
}

.stake-button {
  padding: 8px 16px;
  background-color: #461686; /* 按钮深色背景 */
  color: #f0a500;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(141, 90, 223, 0.9);
  margin-top: 0px;
  transition: all 0.2s ease;
}

.stake-button:hover {
  background-color: #461686; /* hover 时背景加深 */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}



.miner_info_container {
  display: flex;
  flex-direction: row; /* 让 miner_info 内部的内容垂直排列 */
  justify-content: center; /* 确保内容顶部对齐 */
  overflow-x: auto; /* 启用横向滚动 */
  flex-wrap: wrap;/* 允许内容换行 */

}

.miner_info {
  display: flex;
  flex-direction: column; /* 让 miner_info 内部的内容垂直排列 */
  justify-content: center; /* 确保内容顶部对齐 */
  color: var(--bitcoin-color2);
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #1b0855;
  padding: 15px;

  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 15px rgba(234, 157, 68, 0.8); /* 边缘火焰阴影 */

}

.miner_info::before {
  content: '';
  position: absolute;
  top: -30px;
  left: -30px;
  width: calc(100% + 60px);
  height: calc(100% + 60px);
  background: radial-gradient(circle at top left, rgba(234, 157, 68, 0.4), rgba(234, 157, 68, 0.1)); /* 主色为#ea9d44 */
  z-index: -1;
  animation: flames 3s ease-in-out infinite;
  border-radius: 50%;
}

@keyframes flames {
  0%, 100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: scale(1.1) rotate(10deg);
    opacity: 0.7;
  }
}

.miner_info::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  box-shadow: inset 0 0 20px rgba(234, 157, 68, 0.5), 0 0 15px rgba(234, 157, 68, 0.8);
  animation: glow 3s ease-in-out infinite;
}

@keyframes glow {
  0%, 100% {
    box-shadow: inset 0 0 20px rgba(234, 157, 68, 0.5), 0 0 15px rgba(234, 157, 68, 0.8);
  }
  50% {
    box-shadow: inset 0 0 30px rgba(234, 157, 68, 0.7), 0 0 25px rgba(234, 157, 68, 1);
  }
}



/**tab 列表**/
.tabs button {
 padding-right: 20px;
  margin-right: 5px;
  margin-top: 40px;
  text-align: left;
  cursor: pointer;
  border: none;
  background-color: transparent; /* 移除背景色 */
  font-size: 20px;
  outline: none;
  color: var(--bitcoin-color);
  border-bottom: 2px solid transparent; /* 默认无下划线 */
  border-radius: 0; /* 移除圆角 */
  position: relative; /* 为圆圈的定位做准备 */

}
/* 第二个 tab 的闪动圆圈 */
.tabs button:nth-child(2)::after {
  content: '';
  position: absolute;
  right: 6px; /* 调整圆圈位置 */
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: green;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(100, 230, 60, 0.8); /* 添加阴影效果 */
  animation: blink 1s infinite; /* 添加闪动效果 */
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.tabs .active {
  border-bottom: 1px solid #6200ea; /* 选中时的下划线 */
}

.tab-content {
  margin-top: 20px;
  margin-right: 30px;
  background-color: #220f5a;
  border-radius: 8px;
  overflow-x: auto;

}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  padding: 15px;
  border-bottom: 1px solid #1b0855; /* 添加分割线 */
  color: var(--bitcoin-color2);

}
ul li:last-child {
  border-bottom: none; /* 去掉最后一个列表项的分割线 */
}

/**tab 列表  end**/

.panel_info_container {
  display: flex;
  flex-direction: row; 
  justify-content: start; /* 确保内容顶部对齐 */
  overflow-x: auto; /* 启用横向滚动 */
  flex-wrap: wrap;/* 允许内容换行 */
  align-items: center;
}
.panel_info_parent {
  display: flex;
  flex-direction: column; 
  justify-content: start; /* 确保内容顶部对齐 */
  overflow-x: auto; /* 启用横向滚动 */
  flex-wrap: wrap;/* 允许内容换行 */
  margin-right: 20px;
  margin-left: 20px;
}


/* 圆形按钮样式 */
.minerSwitch-btn {
  width: auto;
  height: auto;
  border-radius: 10%; /* 圆形 */
  border: 2px solid #dfd32ad9; /* 黑色边框 */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease; /* 平滑过渡效果 */
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 35px;
  margin-top: 0;/* 确保没有额外的 margin */
  box-sizing: border-box; /* 确保 padding 和 border 在宽高内 */

}

/* 启动状态（绿色） */
.minerSwitch.start {
  background-color: green;
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.9);
  animation: blink 2s infinite; /* 添加闪动效果 */

}

/* 暂停状态（红色） */
.minerSwitch.pause {
  background-color: rgb(101, 30, 30);
  animation:none;
  box-shadow: 0px 0px 15px rgba(239, 106, 62, 0.5);
}

/* 暂停状态（红色） */
.minerSwitch.normal {
  background-color: #371855;
  animation:none;
  box-shadow: 0 0 15px rgba(100, 39, 197, 0.9);

}
.minerSwitch.normal:disabled {
  background-color: #483c55;
  color: #787272;
  animation:none;
  box-shadow: 0 0 15px rgba(100, 39, 197, 0.9);

}


.panel_info_normal {
  color: var(--bitcoin-color2);
  border-radius: 8px;
 /*background-color:#1b0855  设置改背景及变成流光边框*/
  padding: 15px;
  position: relative;
  z-index: 1;
  overflow: hidden; /* 防止伪元素超出容器 */
  width: 100%;
}

/**边框流光**/
.panel_info_normal_bg {
  width: 15%;
  color: var(--bitcoin-color2);
  background-color: #1b0855;
  padding: 2px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom:20px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px #000;
  overflow: hidden;
}
.panel_info_normal_bg::before {
  position: absolute;
  top: -100px;
  content: '';
  width: 100%;
  height: 160%;
  background: linear-gradient(90deg, transparent,
  #371855, transparent);
  animation: rotation 5000ms infinite linear;
}

.panel_info_normal_bg-content {
  position: absolute;
  top: 0.5%;
  left: 0.5%;
  width: 99%;
  height: 99%;
  background-color: #151515;
  border-radius: 5px;
  color: white;
}
/**
* 定义了名为 rotation 的关键帧动画。从0%到100%，旋转 transform 从0度变为360度，实现圆环的旋转效果。
*/
@keyframes rotation {
  0% {
      transform: rotateZ(0deg);
  }

  0% {
      transform: rotateZ(-360deg);
  }
}
/**边框流光  end**/




.panel_info {
  display: flex;
  flex-direction: column; /* 让 miner_info 内部的内容垂直排列 */
  justify-content: center; /* 确保内容顶部对齐 */
  color: var(--bitcoin-color2);
  margin-right: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #1b0855;
  padding: 15px;
  position: relative; /* 使伪元素相对于 .panel_info 定位 */
  box-shadow: 0px 0px 10px 1px #000;

}

.detail-link {
  color: blue; /* 自定义字体颜色 */
  cursor: pointer; /* 鼠标指针变为手型，表示可点击 */
  text-decoration: underline; /* 添加下划线 */
}

.detail-link:hover {
  color: darkblue; /* 悬停时的颜色 */
}


/* 在较小屏幕上，设置垂直排列 */
@media (max-width: 768px) {
  .horizontal-container {
    flex-direction: column; /* 改为垂直排列 */
  }
  .mint-details {
    width: 100%; /* 宽度占满屏幕 */
    margin-bottom: 20px; /* 每个 mint-details 底部增加间距 */
  }
  .miner_info {
    width: 90%;
  }
  .panel_info_normal_bg {
    width: 80%;
    margin-left: auto; /* 居中 */
    margin-right: auto; /* 居中 */
  }
   /* 对长字段的文字进行截断 */
   .hash-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px; /* 限制宽度 */
  }

}

/* 隐藏滚动条 */
.block-list {
  -ms-overflow-style: none;
  /* 适用于IE和Edge浏览器 */
  scrollbar-width: none;
  /* 适用于Firefox浏览器 */
}

.block-list::-webkit-scrollbar {
  display: none;
  /* 适用于Chrome、Safari和Opera浏览器 */
}

.empty-message {
  color: rgb(136, 112, 112); /* 提示颜色为红色 */
  font-style: italic; /* 提示信息显示为斜体 */
  text-align: center; /* 居中显示提示信息 */
  padding: 20px;
}

/**我的收益**/
.token-images {
  position: relative;
  width: 100%;  /* 容器宽度 */
  height: 100%; /* 容器高度 */
}

.floating-image {
  position: absolute;
  width: 60px;  /* 图片的大小 */
  height: 60px;
  animation: float 5s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(10px, -10px);
  }
  50% {
    transform: translate(-10px, 10px);
  }
  75% {
    transform: translate(10px, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/**小气泡**/
.bubble_small {
  position: absolute;
  background-color: rgba(234, 157, 68, 0.5); /* 浅蓝色气泡，透明度 70% */
  border-radius: 50%;
  animation: float_smalll 5s ease-in infinite; /* 气泡的浮动动画 */
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.9);

}

@keyframes float_smalll {
  0% {
    transform: translateY(0); /* 气泡起始位置 */
  }
  100% {
    transform: translateY(-300px); /* 气泡向上移动300px */
  }
}


/**挖矿输出箭头**/
.marquee-wrapper {
  position: relative;
  width: 120px; /* 可根据需求调整 */
  height: 40px; /* 确保容器有足够高度容纳两个箭头 */
  align-self: center;
}
.marquee-container {
  display: flex; /* 让两个箭头序列并排放置 */
  position: absolute;
  overflow: hidden;
  width: 100%;
}

.moving-arrows {
  display: inline-block;
  white-space: nowrap;
  font-size: 30px;
}

.moving-arrows.light {
  color: #ea9d44;
  animation: moveRight 5s linear infinite; /* 不间断移动 */

}

.moving-arrows.dark {
  color: rgba(234, 157, 68, 0.5);

}

@keyframes moveRight {
  0% {
    transform: translateX(-100%); /* 第一个箭头从屏幕外左边开始，第二个箭头还没进场 */
  }
  50% {
    transform: translateX(-50%); /* 第一个箭头到中间，第二个箭头开始进场 */
  }
  100% {
    transform: translateX(0); /* 第二个箭头完全进场 */
  }
}
