:root {
  --bitcoin-color: #ea9d44;
  --bitcoin-color2: #b57934;

  --bitcoin-color_hover: #85680a;

  --background-color: #000000;
  --background-color_gray: #1f1e1d;
  --background-color_gray1: #292827;

  --text-color1: #0e0b0b;
  --text-color2: #666;
  --text-color3: #c4bebe;
  --text-color4: #fff;
  --response-color: #c4bebe;
  --link-color: #61dafb;
  --shadow-color—blue: #007BFF;
  --shadow-color—Purple: #4d24c6;

  --background-gradient-start: #2b146f;
  /* 渐变开始颜色 */
  --background-gradient-end: #010101;
  /* 渐变结束颜色 */
}

#root {
  height: 100%;
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  position: relative;
  /* 允许子元素使用绝对定位 */
  flex-direction: column;
  /* 垂直方向排列 */
  margin-bottom: 50%;

}


.hidden {
  visibility: hidden;
}

.App {
  text-align: center;
}

/* background: linear-gradient(360deg, var(--background-gradient-start), var(--background-gradient-end)); */
html,
body {
  /* 使用线性渐变 */
  background: linear-gradient(360deg, var(--background-gradient-start), var(--background-gradient-end));
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: Arial, sans-serif;
  overflow: scroll;
  overflow-x: hidden;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
}

.title {
  display: flex;
  align-items: center;
  /* 使子元素在垂直方向上居中对齐 */
  margin-left: 20px;
  width: 100%;
  /* 让 .title 占据父容器的宽度 */
  font-family: 'Lobster', 'Courier New', monospace;
  /* 使用自定义字体 */

}

.title-text {
  display: block;
  /* 让 span 变成块级元素 */
  font-size: 30px;
  /* 自定义字体大小 */
  font-weight: bold;
  /* 字体加粗 */
  color: var(--bitcoin-color);
}

.home-link {
  margin-left: 20px;
  margin-bottom: 5px;
  color: #d88c35;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  padding-bottom: 2px;
  cursor: pointer;
}

.home-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 100%;
  background: linear-gradient(to right, #f0a500, transparent);
  transition: all 0.3s;
}

.home-link:hover::after {
  width: 100%;
  background: linear-gradient(to right, #f0a500, transparent);
}

.text-with-shadow {
  text-shadow: 4px 4px 8px var(--shadow-color—Purple);
}

.button-container {
  display: flex;
  justify-content: center;
  /* 居中对齐 */
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* 容器宽度 */
}

.button-container-normal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-style-connect {
  cursor: pointer;
  display: inline-block;
  /* 确保元素为块级元素 */
  align-items: center;
  position: absolute;
  right: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 2px solid var(--text-color2);
  /* 设置边框颜色 */
  border-radius: 50px;
  /* 圆角边框 */
  color: var(--bitcoin-color);
  background-color: null;
  /* 设置响应文本的颜色 */
  font-size: 16px;
  /* 设置响应文本的字体大小 */
  text-align: center;
}

.button-style-connect .icon {
  font-size: 14px;
  /* 调整图标大小 */
  margin-left: 5px;
  /* 图标与文本之间的间距 */

}

.button-style-normal {
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  border: 2px solid var(--text-color2);
  /* 设置边框颜色 */
  border-radius: 50px;
  /* 圆角边框 */
  color: var(--text-color4);
  background-color: var(--background-color);
  /* 设置响应文本的颜色 */
  font-size: 22px;
  /* 设置响应文本的字体大小 */
  text-align: center;
}

.button-style-normal:hover {
  background-color: var(--background-gradient-start);
}

.button-style-normal:disabled {
  background-color: var(--background-color_gray1);
  color: #666;
  cursor: not-allowed;
  /* 禁用时的光标样式 */

}

.response-connectWallet {
  margin-left: 30px;
  /* 设置按钮与响应文本之间的间距 */
  color: var(--text-color1);
  /* 设置响应文本的颜色 */
  font-size: 16px;
  /* 设置响应文本的字体大小 */
  text-align: center;
}

.response {
  /* 设置按钮与响应文本之间的间距 */
  color: var(--text-color3);
  /* 设置响应文本的颜色 */
  font-size: 14px;
  /* 设置响应文本的字体大小 */
  text-align: center;
}


.action-container {
  display: flex;
  align-items: center;
}

.token-buttons-container {
  display: flex;
  justify-content: center;
  /* 确保按钮在父容器中居中对齐 */

  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
}

.token-button {
  width: 8vw;
  /* 按钮宽度占屏幕宽度的 8% */
  height: 8vw;
  /* 确保按钮高度和宽度相等 */
  border-radius: 50%;
  /* 圆形按钮 */
  border: none;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* 默认阴影 */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  /* 调整字体大小以适应圆形按钮 */
  text-align: center;
  position: relative;
  /* 确保伪元素定位正确 */
  box-shadow: 0 0 30px rgba(255, 215, 0, 0.9);

}

/* 仅适用于宽度小于 768px 的设备（通常是手机） */
@media (max-width: 768px) {
  .token-button {
    width: 15vw;
    height: 15vw;
  }
}

.token-button.selected {
  /* border: 2px solid yellow; */
  /* 更均匀的阴影 */
  border: 3px solid rgb(28, 199, 28);
  /* 选中时的绿色边框 */

}

.token-button:disabled {
  filter: brightness(0.4);
  /* 变暗效果 */
  cursor: not-allowed;
  /* 禁用时的光标样式 */

}

.token-button:hover {
  box-shadow: 0 0 30px rgba(255, 215, 0, 0.9);
  /* 更均匀的阴影 */
}

/* .token-button:active {
    transform: scale(0.95);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  } */

.token-name {
  font-size: 16px;
  /* 设置 tokenName 的字体大小 */
  font-weight: bold;
}

.token-type {
  font-size: 13px;
  /* 设置 tokenType 的字体大小 */
  margin-left: 5px;
  /* 添加一些间距 */
  color: var(--text-color1);
}

.App-link {
  color: var(--link-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* 自定义弹窗样式 */
.custom-swal-popup {
  width: auto;
  /* 确保宽度不会影响布局 */
  background-color: var(--background-color_gray);
  /* 更改弹窗背景颜色 */
}

/* 自定义标题样式 */
.custom-swal-title {
  font-size: 24px;
  color: var(--text-color1);
}

/* 自定义内容样式 */
.custom-swal-content {
  font-size: 18px;
  color: #666;

}

/**请求菊花**/
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* 确保加载器位于最上层 */
  visibility: hidden;
  /* 初始状态隐藏 */
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid var(--bitcoin-color);
  ;
  border-radius: 30%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/**请求菊花end**/

/**marquee**/
.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 20px;
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  /* 确保内容不换行 */

}

.marquee-item {
  display: inline-block;
  font-size: 18px;
  color: var(--bitcoin-color);
  margin-right: 50px;
  /* 设置每个item之间的间距 */
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/**marquee**/