/**recordTabs**/
.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    /* 背景遮罩 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.record-overlay-new{
    width: 80%;
    height: 50%;
    background-color: #220f5a;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    /* 防止内容溢出视口 */
    display: flex;
    flex-direction: column;    /* 垂直排列 */
    overflow-y: auto; /* 超出部分滚动 */
    position: relative;
}



.recordTabs {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    /* 水平居中对齐 */
    margin-left: 2px;
    margin-top: 10px;
}

.recordTabs button {
    background-color: #371855;
    border: none;
    padding: 10px 20px;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 30px 0 0 30px;
    /* 给按钮添加圆角 */
    transition: background-color 0.3s ease;
    box-shadow: 0 0 5px rgba(100, 39, 197, 0.9);
    flex: 1;
    max-width: 150px;
}



/* 第二个Tab */
.recordTabs button:last-child {
    border-radius: 0 30px 30px 0;
    /* 给按钮添加右侧圆角 */
}

/* 选中状态的样式 */
.recordTabs button.active {
    background-color: #240742;
    color: rgb(135, 122, 122);
}

/* 鼠标悬停样式 */
.recordTabs button:hover {
    background-color: #240742;
    color: rgb(135, 122, 122);

}


.tab-content {
    flex-grow: 1;
    border-radius: 8px;
    overflow-y: auto;
}

ul {
    list-style-type: none;
    padding: 0;
}

ul li {
    padding: 15px;
    border-bottom: 1px solid #1b0855;
    /* 添加分割线 */
    color: var(--bitcoin-color2);

}

ul li:last-child {
    border-bottom: none;
    /* 去掉最后一个列表项的分割线 */
}

  
.close-button {
    margin-top: 10px;
    width: 100px;
    align-self: center;
    padding: 7px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #371855;
    animation:none;
    box-shadow: 0 0 5px rgba(100, 39, 197, 0.9);
  }