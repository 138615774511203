/* Record.css */

.record-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* 背景遮罩 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.record {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 将内部元素居中对齐 */
    background-color: var(--background-color_gray);
    padding: 20px;
    border-radius: 8px;
    max-height: 60%;
    overflow: hidden;/* 确保容器内部溢出部分隐藏 */
}

.table-container {
    width: 100%;
    max-height: 40%;
    overflow-y: auto;  /* 允许垂直滚动 */
}

.h2 {
    color: var(--bitcoin-color);
    padding: 5px;
}

table {
    width: 100%;
      border-collapse: collapse;
}

th {
    color: var(--bitcoin-color);
    padding: 12px;
    text-align: start;
}

td {
    color: var(--response-color);
    padding: 12px;
    text-align: start;
    border-bottom: 0.5px solid #3a3838;
    /* 添加底部边框 */
}

button {
    margin-top: 20px;
    margin-right: 10px;
    padding: 10px;
    background: var(--bitcoin-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
