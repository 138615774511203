/* Block container */
.block-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Height style */
.block-height {
  font-size: 18px;
  color: #4d24c6;
  margin-bottom: 10px;
  margin-right: 30px;
}
/* 区块的容器样式 */
.block {
  flex: 0 0 auto; /* 保持每个区块的宽度固定，防止被压缩 */
  width: 120px; /* 设置区块的固定宽度 */
  height: 120px;
  background: linear-gradient(to bottom, #2a177d, #371855);
  margin: 20px;
  color: #fff;
  text-align: center;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  transform-style: preserve-3d;
}


/* 左侧面 */
.block::before {
  content: '';
  position: absolute;
  top: 0;
  left: -25px;
  width: 25px;
  height: 100%;
  background: linear-gradient(to bottom, #16093c, #16093c); /* 渐变色模拟左侧阴影 */
  transform: skewY(45deg);
  transform-origin: top right;
  z-index: -1;
}

/* 顶部面 */
.block::after {
  content: '';
  position: absolute;
  top: -25px;
  left: 0;
  width: 100%;
  height: 25px;
  background: linear-gradient(to right, #16093c, #16093c); /* 渐变色模拟顶部阴影 */
  transform: skewX(45deg); /* 设置为正值，使其向前倾斜 */
  transform-origin: bottom left;
  z-index: -1;
}

/* 区块内容样式 */
.block-content {
  margin-top: 15px;
  font-size: 12px;
  position: relative;
  z-index: 1;
}

.block-content div {
  margin-top: 8px; /* 每个 div 的底部增加间距 */
}


/* 矿工信息的样式 */
.miner-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #fff;
  font-size: 12px;
}
.miner-info img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

/* 区块箭头指示器 */
/* .arrow-indicator {
  left: 50%;
  margin-top: 10px;
  font-size: 24px;
  color: #5ed5f7;
} */


/* 区块箭头指示器 */
.arrow-indicator {
  margin-top: 10px; /* 调整箭头的位置，使其位于区块的下方 */
  transform: translateX(-50%) rotate(0deg); /* 翻转箭头，指向上 */
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #4d24c6; /* 箭头颜色 */
}

