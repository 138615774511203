.writer_tx {
    margin-top: 10px;
    font-size: 35px;
    margin-left: 5%;
    margin-right: 5%;
    line-height: 1.5; /* 设置行间距 */
    text-align: center;
    font-family: 'Lobster', 'Courier New', monospace; /* 使用自定义字体 */
    text-shadow: 0 0 5px #f39c12, 0 0 15px var(--shadow-color—Purple), 0 0 15px var(--shadow-color—Purple);
    animation: color-change 5s infinite; /* 添加颜色渐变和放大缩小动画  zoom 3s infinite*/

  }
  
/* 闪烁光标的动画 */
.cursor {
    display: inline-block;
    margin-left: 2px;
    animation: blink 1s step-start infinite;
  }
  
  /* 闪烁动画 */
  @keyframes blink {
    0%, 50% {
      opacity: 1;
    }
    50.01%, 100% {
      opacity: 0;
    }
  }
  
  /* 渐显效果 */
  /* .typewriter {
    animation: fade-in 1s ease-in-out;
  }
   */
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* 颜色渐变动画 */
@keyframes color-change {
    0% {
        color: var(--bitcoin-color)
      }
      50% {
        color:#28156b
      }
      100% {
        color: var(--bitcoin-color)
      }
  }
  
  /* 放大缩小动画 */
  @keyframes zoom {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2); /* 放大 */
    }
    100% {
      transform: scale(1);
    }
  }