table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  margin-bottom: 100px;
}

th,
td {
  padding: 12px;
  text-align: left;
  color: var(--bitcoin-color2);
  font-size: 15px;
}

th {
  color: #8772c4;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #8772c4;
}


td {
  border-bottom: 1px solid #1b0855;
  /* 添加分割线 */

}